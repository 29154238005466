.list {
  &-main {
    position: relative;
    z-index: 5;

    ul {
      padding: 0;
      max-height: 0;
      position: absolute;
      background-color: white;
      color: var(--Black);
      overflow: auto;
      box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 40%);

      transition: max-height 0.3s ease;
    }
  }
}

.active-list {
  @extend .list-main;

  ul {
    max-height: 300px;
    transition: max-height 0.5s ease;
  }
}

.modal {
  &-filter {
    position: fixed;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 14px;
    background-color: white;
    color: var(--Black);
    z-index: 999;
  }

  &-item {
    display: flex;
    align-items: center;
  }
}