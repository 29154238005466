@use 'react-md' as *;
@tailwind base;
@tailwind components;
@tailwind utilities;

@include react-md-utils;

:root {
  --OrangeClinicorp: #fc673d;
  --NewOrangeClinicorp: #a73804;
  --RedStatus: #e53935;
  --ErrorColor: #f44336;
  --GreenAmount: #388e3c;
  --White: #E5E5E5;
  --WhiteGrey: #969696;
  --Black: #252525;
  --GreyBlack: #1C1E1F;
  --NewGreyBlack: #111111;
  --Grey: #252525;
  --Blue: #071fff;
  --BackgroundTableHeader: var(--NewOrangeClinicorp);
  --ColorTableRow: var(--White);
  --BackgroundTableFooter: var(--Black);
  --ColorBtnTableFooterSelected: var(--NewOrangeClinicorp);
  --BackgroundBtnTableFooterSelected: var(--Grey);
  --ColorBtnTableFooterNotSelected: var(--White);
  --BackgroundTableRow1: var(--Grey);
  --BackgroundTableRow2: var(--GreyBlack);
  --rmd-avatar-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[role=checkbox] {
  box-sizing: unset !important;
  padding: 0 5px 0px 0px;
}

.modal-maintenance-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 999;
  background-color: var(--Black);
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-maintenance {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-maintenance {
  &-container {
    border-radius: 15px;
    height: 150px;
    width: 400px;
    background-color: var(--White);
  }

  &-title {
    display: flex;
    border-radius: 14px 14px 0 0;
    justify-content: center;
    background-color: #dc7b25;
    align-items: center;
    justify-content: center;
    color: var(--White);
  }

  &-body {
    padding: 20px;
    display: flex;
  }
}