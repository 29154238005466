.workbench {
  &-table {
    &-container {
      border-radius: 10px;
      width: 100%;
      height: 700px;
      max-height: 70vh;
    }

    &-header {
      background-color: var(--BackgroundTableHeader);
      border-radius: 10px 10px 0 0;

      &-cell {
        height: 50px;
        background-color: var(--BackgroundTableHeader);
        color: var(--ColorTableRow);
      }
    }
    &-body {
      background-color: var(--Black);
      & tr:hover {
        opacity: 0.8;
      }

      &-row {
        &-1 {
          background-color: var(--BackgroundTableRow1);
        }

        &-2 {
          background-color: var(--BackgroundTableRow2);
        }
      }
      &-cell {
        color: var(--ColorTableRow);
      }
    }

    &-footer {
      &-cell{
        height: 50px;
        bottom: -1px;
        border-radius: 0 0 10px 10px;
        background-color: var(--BackgroundTableFooter);

        &-btn {
          &-selected {
            color: var(--ColorBtnTableFooterSelected);
            background-color: var(--BackgroundBtnTableFooterSelected);
          }

          &-notSelected {
            color: var(--ColorBtnTableFooterNotSelected);

            &:hover{
              color: var(--ColorBtnTableFooterSelected);
              background-color: var(--BackgroundBtnTableFooterSelected);
            }
          }
        }
        &-icon{
          &-more-horiz {
            width: 40px;
            color: var(--White);
          }
        }
      }
    }
  }

  &-menu {
    border-radius: 15px;
    height: 50px;
    background-color: var(--Black);
    padding: 0 25px;
    margin-bottom: 25px;
    button {
      color: var(--White);
  }
  }
}

