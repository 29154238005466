
.working-build {
  color: #1970d4;
  animation: working-build 2s linear infinite;
}

@keyframes working-build {
  to{ transform: rotate(360deg) }
}

.fail-build {
  color: var(--RedStatus);
}

.success-build {
  color: #005700;
}
.queued-build {
  color: #a66d3b;
}

.cancelled-build {
  color: var(--WhiteGrey);
}

.cancel-build {
  color: var(--NewOrangeClinicorp);
}

.testing-build {
  animation: working-build 3s linear infinite;
  color: #01d5d2;
}

@keyframes testing-build {
  to{ transform: rotate(360deg) }
}

.waiting-to-promote {
  color: #3997FF;
}

.warning-build {
  color: #b89509;
}

.testing-failure {
  color: #802323;
}

.promoting {
  animation: working-build 2s alternate-reverse infinite;
  color: #3997FF;
}

@keyframes promoting {
  to{ transform: rotate(360deg) }
}

.svg-list-build {
  width: 25px;
}

.dislplay-username-list-build {
  color: var(--WhiteGrey)
}