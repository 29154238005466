.operational {
  background-color: #005700;
}

.under_maintenance {
  background-color: #3997FF ;
}

.degraded_performance {
  background-color: #a66d3b
}
.partial_outage { 
background-color: #a73804
}

.major_outage { 
background-color: #f44336
}